<template>
  <div>
    <div
      :style="isFirstVideo ? 'opacity: 0; pointer-events: none' : ''"
      class="container"
      @click.stop="setCurrentIndex(videoIndex - 1)"
      @touchstart.stop="setCurrentIndex(videoIndex - 1)"
    >
      <button class="arrow-container up">
        <Arrow />
      </button>
    </div>
    <div
      :style="isLastVideo ? 'opacity: 0; pointer-events: none' : ''"
      class="container"
      @click.stop="setCurrentIndex(videoIndex + 1)"
      @touchstart.stop="setCurrentIndex(videoIndex + 1)"
    >
      <button class="arrow-container down">
        <ArrowDown />
      </button>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/arrow.svg";
import ArrowDown from "@/assets/arrow-down.svg";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "NavigationArrows",
  components: {
    Arrow,
    ArrowDown,
  },
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("videos", ["isLastVideo", "isFirstVideo"]),
  },
  methods: {
    ...mapMutations({
      setCurrentIndex: "videos/SET_CURRENT_INDEX",
    }),
  },
};
</script>

<style lang="scss" scoped>
.arrow-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $transparent-black;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(0.9);
    fill: white;
  }
}

.isDesktop {
  .arrow-container:hover {
    transform: scale(1.1);
    transition: $button-transition;
  }
}

.isFullScreen {
  .arrow-container {
    width: 40px;
    height: 40px;
    svg {
      transform: scale(1.1);
    }
  }
  &.isHorizontalSwipe .navigation-arrows-container {
    position: absolute;
    z-index: 1;
    display: flex;
    width: calc(100% + 112px);
    justify-content: space-between;
    // 56 is half of 112 which is arrow container * 2 + 16px margin on each side
    transform: translate(56px, -50%);
    right: 0%;
    gap: 0;
    flex-direction: row;
    .container {
      transform: rotate(-90deg);
    }
  }
}
</style>
